<template>
	<main class="page-container" id="sme-noticeinfo-view">
		<div class="page-component">
			<div class="page-header blind">
				<div class="page-component">
					<div class="page-header-title">
						<h2 class="title">공지사항</h2>
					</div>
					<div class="page-header-hashtags">
						<p class="text">{{ tags }}</p>
					</div>
				</div>
			</div>
			<div class="page-body">
				<section class="notice-post-view-wrapper">
					<header class="post-view-header">
						<div class="header-category">
							<div class="badge">
								<span class="badge-text">{{ ntcCtgr }}</span>
							</div>
						</div>

						<h3 class="header-title">{{ ntcTit }}</h3>
					</header>
					<div class="post-view-body">
						<div class="gachi-view" style="line-height: 3rem">
							<!-- 오류로 v-html="ntcCn" 주석처리 -->
							<div class="board_info">
								<dl>
									<dt style="font-weight: 700; text-align: center">작성자</dt>
									<dd>관리자</dd>
									<dt style="font-weight: 700; text-align: center">등록일</dt>
									<dd>
										<span>{{ regDt }}</span>
									</dd>
									<dt style="font-weight: 700; text-align: center">조회수</dt>
									<dd>
										<span class="text">{{ inqCnt }}</span>
									</dd>
								</dl>
								<dl class="x3">
									<dt style="font-weight: 700; text-align: center">첨부파일</dt>
									<dd>
										<div class="att_wap">
											<div class="att_info">
												<td class="attach">
													<a href="javascript:">
														<span v-bind:class="[activeClass, fileExt]" @click="clickDownload"></span>
														<span class="att_name" @click="clickDownload">{{ atchFileNm }}</span>
													</a>
												</td>
											</div>
										</div>
									</dd>
								</dl>
							</div>
							<div class="board_content">
								<div class="gachi-view" v-html="ntcCn"></div>
							</div>
							<!-- <ul class="board_move">
                <li class="prev">
                  <dl>
                    <dt>이전글</dt>
                    <dd>
                      <a href=""></a>
                    </dd>
                  </dl>
                </li>
                <li class="next">
                  <dl>
                    <dt>다음글</dt>
                    <dd>
                      <a href=""></a>
                    </dd>
                  </dl>
                </li>
              </ul> -->
						</div>
					</div>
				</section>

				<div class="page-buttons" data-align="center">
					<a href="javascript:"> </a>
					<div v-if="isMobile" class="page-buttons" data-align="center" style="flex-direction: row; width: 100%; margin: auto">
						<a href="javascript:">
							<next-prev-button route-name="NoticeView" param-key="ntcId" queryKey="ctgrCode" :query="$route.query.ctgrCode" :pre-id="preId" :next-id="nextId"></next-prev-button>
						</a>
					</div>
					<div v-else class="page-buttons" data-align="center" style="flex-direction: row; width: 900px; margin: auto">
						<a href="javascript:">
							<next-prev-button route-name="NoticeView" param-key="ntcId" queryKey="ctgrCode" :query="$route.query.ctgrCode" :pre-id="preId" :next-id="nextId"></next-prev-button>
						</a>
					</div>
					<div v-if="isMobile" class="list-btn-cont" style="width: 100px; position: absolute; left: calc(50% - 50px); top: calc(100% - 100px)">
						<button class="button-default is-large is-rounded is-secondary" @click="goList">
							<span class="text">목록</span>
						</button>
					</div>
					<div v-else class="list-btn-cont" style="width: 150px; position: absolute; left: calc(50% - 74px); top: calc(100% - 56px)">
						<button class="button-default is-large is-rounded is-secondary" @click="goList">
							<span class="text">목록</span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import { ACT_DOWN_ATTACH_NOTICE2, ACT_GET_NOTICE2 } from '../../../store/_act_consts';
import { getItem, lengthCheck, queryToValue, viewTags } from '../../../assets/js/utils';
import { viewCount } from '../../../assets/js/func';
import NextPrevButton from '../../../components/common/NextPrevButton';
import { mapGetters } from 'vuex';

export default {
	name: 'NoticeView',
	components: {
		NextPrevButton,
	},
	watch: {
		$route(to) {
			this.ntcId = queryToValue(to.params.ntcId, true, 0);
			this.getNtc();
		},
	},
	data: () => ({
		ntcId: 0,
		ntcCtgr: '',
		ntcTit: '',
		tags: '',
		ntcCn: '',
		atchFileNm: '',
		atchFilePtUrl: '',
		downing: false,
		preId: 0,
		nextId: 0,
		fileExt: '',
		regDt: '',
		activeClass: 'att_icon',
		inqCnt: 0,
	}),
	computed: {
		...mapGetters('common', ['isMobile']),
		classObject: function () {
			return {
				active: this.fileExt,
			};
		},
	},
	created() {
		this.ntcId = this.$route.params.ntcId;
		viewCount(this.ntcId, 'ntc');
		this.getNtc();
	},
	methods: {
		goList() {
			this.$router.push({ name: 'Notice', query: { ctgrCode: this.$route.query.ctgrCode } });
		},
		getNtc() {
			this.$store
				.dispatch(`notice2/${ACT_GET_NOTICE2}`, {
					ntcId: this.ntcId,
					ntcCtgrDcd: this.$route.query.ctgrCode,
				})
				.then((res) => {
					if (lengthCheck(res)) {
						const item = getItem(res);
						this.ntcId = item.ntcId;
						this.ntcCtgr = item.ntcCtgr;
						this.ntcTit = item.ntcTit;
						this.tags = viewTags(item, 'ntcTag');
						this.ntcCn = item.ntcCn;
						this.atchFileNm = item.atchFileNm;
						this.atchFilePtUrl = item.atchFilePtUrl;
						this.preId = item.preId;
						this.nextId = item.nextId;
						this.fileExt = item.fileExt;
						this.inqCnt = item.inqCnt;
						this.regDt = new Date(item.regDt).getFullYear() + '-' + ('00' + (new Date(item.regDt).getMonth() + 1).toString()).slice(-2) + '-' + ('00' + new Date(item.regDt).getDate().toString()).slice(-2);
					} else {
						this.ntcId = 0;
					}
				})
				.catch((e) => {
					console.error(e);
					this.ntcId = 0;
				});
		},
		clickDownload() {
			// window.open(`${this.atchFilePtUrl}`);
			this.downing = true;
			this.$store
				.dispatch(`notice2/${ACT_DOWN_ATTACH_NOTICE2}`, {
					ntcId: this.ntcId,
					fileName: this.atchFileNm,
				})
				.then((res) => {
					const url = window.URL.createObjectURL(new Blob([res.data]));
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', this.atchFileNm);
					document.body.appendChild(link);
					link.click();
					this.downing = false;
				})
				.catch((err) => {
					console.log(err);
					this.downing = false;
				});
		},
	},
};
</script>
